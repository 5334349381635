import React, { Component } from 'react';
import Switch from 'react-toggle-switch';
import { kanaDictionary } from '../../data/kanaDictionary';
import './ChooseCharacters.scss';
import CharacterGroup from './CharacterGroup';

class ChooseCharacters extends Component {
  state = {
    errMsg : '',
    selectedGroups: this.props.selectedGroups,
    showAlternatives: [],
    showSimilars: [],
    startIsVisible: true
  }

  componentDidMount() {
    this.testIsStartVisible();
    window.addEventListener('resize', this.testIsStartVisible);
    window.addEventListener('scroll', this.testIsStartVisible);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.testIsStartVisible);
    window.removeEventListener('scroll', this.testIsStartVisible);
  }

  componentDidUpdate(prevProps, prevState) {
    this.testIsStartVisible();
    if (prevProps.errors !== this.props.errors) {
      console.log("Errors updated in ShowStage:", this.props.errors);
    }
  }

  testIsStartVisible = () => {
    if(this.startRef) {
      const rect = this.startRef.getBoundingClientRect();
      if(rect.y > window.innerHeight && this.state.startIsVisible)
        this.setState({ startIsVisible: false });
      else if(rect.y <= window.innerHeight && !this.state.startIsVisible)
        this.setState({ startIsVisible: true });
    }
  }

  scrollToStart() {
    if(this.startRef) {
      const rect = this.startRef.getBoundingClientRect();
      const absTop = rect.top + window.pageYOffset;
      const scrollPos = absTop - window.innerHeight + 50;
      window.scrollTo(0, scrollPos > 0 ? scrollPos : 0);
    }
  }

  getIndex(groupName) {
    return this.state.selectedGroups.indexOf(groupName);
  }

  isSelected(groupName) {
    return this.getIndex(groupName) > -1 ? true : false;
  }

  removeSelect(groupName) {
    if(this.getIndex(groupName)<0)
      return;
    let newSelectedGroups = this.state.selectedGroups.slice();
    newSelectedGroups.splice(this.getIndex(groupName), 1);
    this.setState({selectedGroups: newSelectedGroups});
  }

  addSelect(groupName) {
    this.setState({errMsg: '', selectedGroups: this.state.selectedGroups.concat(groupName)});
  }

  toggleSelect = groupName => {
    if(this.getIndex(groupName) > -1)
      this.removeSelect(groupName);
    else
      this.addSelect(groupName);
  }

  selectAll(whichKana, altOnly=false, similarOnly=false) {
    const thisKana = kanaDictionary[whichKana];
    let newSelectedGroups = this.state.selectedGroups.slice();
    Object.keys(thisKana).forEach(groupName => {
      if(!this.isSelected(groupName) && (
        (altOnly && groupName.endsWith('_a')) ||
        (similarOnly && groupName.endsWith('_s')) ||
        (!altOnly && !similarOnly)
      ))
        newSelectedGroups.push(groupName);
    });
    this.setState({errMsg: '', selectedGroups: newSelectedGroups});
  }

  selectNone(whichKana, altOnly=false, similarOnly=false) {
    let newSelectedGroups = [];
    this.state.selectedGroups.forEach(groupName => {
      let mustBeRemoved = false;
      Object.keys(kanaDictionary[whichKana]).forEach(removableGroupName => {
        if(removableGroupName === groupName && (
          (altOnly && groupName.endsWith('_a')) ||
          (similarOnly && groupName.endsWith('_s')) ||
          (!altOnly && !similarOnly)
        ))
          mustBeRemoved = true;
      });
      if(!mustBeRemoved)
        newSelectedGroups.push(groupName);
    });
    this.setState({selectedGroups: newSelectedGroups});
  }

  toggleAlternative(whichKana, postfix) {
    let show = postfix == '_a' ? this.state.showAlternatives : this.state.showSimilars;
    const idx = show.indexOf(whichKana);
    if(idx >= 0)
      show.splice(idx, 1);
    else
      show.push(whichKana)
    if(postfix == '_a')
      this.setState({showAlternatives: show});
    if(postfix == '_s')
      this.setState({showSimilars: show});
  }

  getSelectedAlternatives(whichKana, postfix) {
    return this.state.selectedGroups.filter(groupName => {
      return groupName.startsWith(whichKana == 'hiragana' ? 'h_' : 'k_') &&
        groupName.endsWith(postfix);
    }).length;
  }

  getAmountOfAlternatives(whichKana, postfix) {
    return Object.keys(kanaDictionary[whichKana]).filter(groupName => {
      return groupName.endsWith(postfix);
    }).length;
  }

  alternativeToggleRow(whichKana, postfix, show) {
    let checkBtn = "glyphicon glyphicon-small glyphicon-"
    let status;
    if(this.getSelectedAlternatives(whichKana, postfix) >= this.getAmountOfAlternatives(whichKana, postfix))
      status = 'check';
    else if(this.getSelectedAlternatives(whichKana, postfix) > 0)
      status = 'check half';
    else
      status = 'unchecked'
    checkBtn += status

    return <div
      key={'alt_toggle_' + whichKana + postfix}
      onClick={() => this.toggleAlternative(whichKana, postfix)}
      className="choose-row"
    >
      <span
        className={checkBtn}
        onClick={ e => {
          if(status == 'check')
            this.selectNone(whichKana, postfix == '_a', postfix == '_s');
          else if(status == 'check half' || status == 'unchecked')
            this.selectAll(whichKana, postfix == '_a', postfix == '_s');
          e.stopPropagation();
        }}
      ></span>
      {
        show ? <span className="toggle-caret">&#9650;</span>
          : <span className="toggle-caret">&#9660;</span>
      }
      {
        postfix == '_a' ? 'Caratteri alternativi (ga · ba · kya..)' :
          'Caratteri simili (shi · tsu · so · n...)'
      }
    </div>
  }

  showGroupRows(whichKana, showAlternatives, showSimilars = false) {
    const thisKana = kanaDictionary[whichKana];
    let rows = [];
    Object.keys(thisKana).forEach((groupName, idx) => {
      if(groupName == "h_group11_a" || groupName == "k_group13_a")
        rows.push(this.alternativeToggleRow(whichKana, "_a", showAlternatives));
      if(groupName == "k_group11_s")
        rows.push(this.alternativeToggleRow(whichKana, "_s", showSimilars));

      if((!groupName.endsWith("a") || showAlternatives) &&
        (!groupName.endsWith("s") || showSimilars)) {
        rows.push(<CharacterGroup
          key={idx}
          groupName={groupName}
          selected={this.isSelected(groupName)}
          characters={thisKana[groupName].characters}
          handleToggleSelect={this.toggleSelect}
        />);
      }
    });

    return rows;
  }

  startGame() {
    if(this.state.selectedGroups.length < 1) {
      this.setState({ errMsg: 'Scegli almeno un gruppo!'});
      return;
    }
    this.props.handleStartGame(this.state.selectedGroups);
  }

  render() {
    return (
      <div className="choose-characters">
        <div className="row">
          <div className="col-xs-12">
            <div className="panel panel-default">
              <div className="panel-body welcome">
                <h4 style={{fontWeight: 'bold', textAlign: 'center'}}>Benvenuto su Kana Challenge di JAPONIKA!</h4>
                <p style={{textAlign: 'center'}}>Scegli i kana che vuoi studiare.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="panel panel-default">
              <div className="panel-heading">Hiragana · ひらがな</div>
              <div className="panel-body selection-areas">
                {this.showGroupRows('hiragana', this.state.showAlternatives.indexOf('hiragana') >= 0)}
              </div>
              <div className="panel-footer text-center">
                <a href="javascript:;" onClick={()=>this.selectAll('hiragana')}>Tutti</a><a href="javascript:;"
                  onClick={()=>this.selectNone('hiragana')}>Nessuno</a>
                <a href="javascript:;" onClick={()=>this.selectAll('hiragana', true)}>Tutte le alternative</a>
                <a href="javascript:;" onClick={()=>this.selectNone('hiragana', true)}>Nessun alternativo</a>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="panel panel-default">
              <div className="panel-heading">Katakana · カタカナ</div>
              <div className="panel-body selection-areas">
                {this.showGroupRows('katakana', this.state.showAlternatives.indexOf('katakana') >= 0, this.state.showSimilars.indexOf('katakana') >= 0)}
              </div>
              <div className="panel-footer text-center">
                <a href="javascript:;" onClick={()=>this.selectAll('katakana')}>Tutti</a> <a href="javascript:;"
                  onClick={()=>this.selectNone('katakana')}>Nessuno
                </a>
                 <a href="javascript:;" onClick={()=>this.selectAll('katakana', true)}>Tutte le alternative</a>
                 <a href="javascript:;" onClick={()=>this.selectNone('katakana', true)}>Nessun alternativo</a>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-xs-12 pull-right">
            <span className="pull-right lock">Livello Singolo&nbsp;
              {
                this.props.isLocked &&
                  <input className="stage-choice" type="number" min="1" max="4" maxLength="1" size="1"
                    onChange={(e)=>this.props.lockStage(e.target.value, true)}
                    value={this.props.stage}
                  />
              }
              <Switch onClick={()=>this.props.lockStage(1)} on={this.props.isLocked} /></span>
          </div>
          <div className="col-sm-offset-3 col-sm-6 col-xs-12 text-center">
            {
              this.state.errMsg != '' &&
                <div className="error-message">{this.state.errMsg}</div>
            }
            <button ref={c => this.startRef = c} className="btn btn-danger startgame-button" onClick={() => this.startGame()}>Inizia la challenge!</button>
          </div>
          <div className="down-arrow"
            style={{display: this.state.startIsVisible ? 'none' : 'block'}}
            onClick={(e) => this.scrollToStart(e)}
          >
            Inizia
          </div>
          
        </div>
        
            {/* Informational Section */}
      <div className="information-section">
        <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Come funziona?</h4>
        <p style={{ textAlign: 'center' }}>
         <u> Kana si riferisce ai due sistemi di scrittura sillabici utilizzati in giapponese: Hiragana e Katakana.</u>
        </p>
        <p style={{ textAlign: 'center' }}>
        <strong>Kana Challenge</strong> è uno strumento di apprendimento, in forma di quiz, ideale per memorizzare i caratteri kana. <br />
         Non esiste un "<strong>modo corretto</strong>" per utilizzare <strong>Kana Challenge</strong>.  <br />
  Se sei un principiante, ti consigliamo di iniziare selezionando i primi due gruppi (<strong>a - ko</strong>) dell'<strong>hiragana</strong>. 
  <br />Una volta che riesci a superare l'<strong>ultimo livello</strong>, aggiungi gradualmente uno o due gruppi in più. 
  Il tuo obiettivo è la <strong>perfezione</strong>: rispondi il più rapidamente possibile.
</p>
       
        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Divertiti!</p>
      </div>
                {/* Footer Section */}
                <footer className="footer">
  <div className="footer-content text-center">
  <a href="https://japonika.it" target="_blank" rel="noopener noreferrer">
                    <img 
                      src="src/assets/img/jap-logow.png" 
                      alt="Japonika Logo" 
                      className="navbar-logo"
                    />
                  </a>
    <p><strong>CONTATTACI</strong><br />japonika.academia@gmail.com<br />(+39) 388 1272553</p>
    <p>
      <u><a href="https://japonika.it/informativa-privacy/" target="_blank">Informativa Privacy</a></u> &nbsp;|&nbsp; 
      <u><a href="https://japonika.it/cookie-policy/" target="_blank">Cookie Policy</a></u> &nbsp;|&nbsp; 
      <u><a href="http://japonika.it/termini-e-condizioni/" target="_blank">Termini e Condizioni</a></u>
    </p>
  



    <p>JAPONIKA 2024</p>
    <p>
      Antonio Shota Amato | P. IVA 04483790277<br />
      Laura Ricci | P. IVA 05250280269<br />
      Edoardo Jun Bonicelli | P. IVA 04774340279<br /> 
    </p>
    <p>
  made with <span className="heart">❤️</span> by <u><a href="https://www.enfasiweb.com" target="_blank" rel="noopener noreferrer" className="gradient-pill">Enfasi® Design</a></u>
</p>
  </div>
</footer>

      </div>
    );
  }
}

export default ChooseCharacters;
