import React, { Component } from 'react';
import { kanaDictionary } from '../../data/kanaDictionary';
import ChooseCharacters from '../ChooseCharacters/ChooseCharacters';
import Game from '../Game/Game';

const DEBUG_MODE = true; // Imposta su false per disattivare la debug mode

class GameContainer extends Component {
  state = {
    stage: 1,
    isLocked: false,
    decidedGroups: JSON.parse(localStorage.getItem('decidedGroups') || '[]'),
    stageStartTime: null,
    stageTimes: [],
    totalTime: 0,
    debugModeEnabled: DEBUG_MODE,
  }

  componentWillReceiveProps() {
    if(!this.state.isLocked)
      this.setState({stage: 1});
  }

  startGame = (decidedGroups) => {
    this.resetGameTime();
    if(parseInt(this.state.stage)<1 || isNaN(parseInt(this.state.stage)))
      this.setState({stage: 1});
    else if(parseInt(this.state.stage)>4)
      this.setState({stage: 4});

    this.setState({decidedGroups: decidedGroups});
    localStorage.setItem('decidedGroups', JSON.stringify(decidedGroups));
    this.props.handleStartGame();
  }
  

  stageUp = () => {
    this.setState({stage: this.state.stage+1});
  }

  lockStage = (stage, forceLock) => {
    // if(stage<1 || stage>4) stage=1; // don't use this to allow backspace
    if(forceLock)
      this.setState({stage: stage, isLocked: true});
    else
      this.setState({stage: stage, isLocked: !this.state.isLocked});
  }

  startStageTimer = () => {
    console.log('Starting stage timer');
    this.setState({ stageStartTime: Date.now() });
  }

  endStageTimer = () => {
    console.log('Ending stage timer');
    const { stageStartTime, stageTimes } = this.state;
    const stageTime = Math.floor((Date.now() - stageStartTime) / 1000);
    const newStageTimes = [...stageTimes, stageTime];
    const totalTime = newStageTimes.reduce((sum, time) => sum + time, 0);
    this.setState({ stageTimes: newStageTimes, totalTime });
  }

  resetGameTime = () => {
    this.setState({
      stageTimes: [],
      totalTime: 0
    });
  }

  handleStartGame = (selectedGroups) => {
    this.setState({
      stageTimes: [],
      totalTime: 0
    }, () => {
      // Altre operazioni per iniziare il gioco
    });
  }

  toggleDebugMode = () => {
    this.setState(prevState => ({ debugModeEnabled: !prevState.debugModeEnabled }));
  }

  render() {
    console.log('GameContainer render, gameState:', this.props.gameState);
    return (
      <div>
        { this.props.gameState === 'chooseCharacters' &&
            <ChooseCharacters selectedGroups={this.state.decidedGroups}
              handleStartGame={this.startGame}
              stage={this.state.stage}
              isLocked={this.state.isLocked}
              lockStage={this.lockStage}
            />
          }
          { this.props.gameState === 'game' &&
              <Game
                decidedGroups={this.state.decidedGroups}
                handleEndGame={this.props.handleEndGame}
                stageUp={this.stageUp}
                stage={this.state.stage}
                isLocked={this.state.isLocked}
                lockStage={this.lockStage}
                startStageTimer={this.startStageTimer}
                endStageTimer={this.endStageTimer}
                stageTimes={this.state.stageTimes}
                totalTime={this.state.totalTime}
                debugModeEnabled={this.state.debugModeEnabled}
                toggleDebugMode={this.toggleDebugMode}
              />
          }
        </div>
    )
  }
}

export default GameContainer;
