export const ACHIEVEMENTS = {
  HIRAGANA: {
    ASHIGARU: { name: '足軽 ASHIGARU', condition: 'basicHiragana', time: Infinity },
    TAISHO: { name: '大将 TAISHŌ', condition: 'basicHiragana', time: 300 },
    SAMURAI: { name: '侍 SAMURAI', condition: 'allHiragana', time: Infinity },
    DAIMYO: { name: '大名 DAIMYŌ', condition: 'allHiragana', time: 300 },
    SHOGUN: { name: '将軍 SHŌGUN', condition: 'allHiragana', time: 180 },
    TOMOE_GOZEN: { name: '巴御前 TOMOE-GOZEN', condition: 'allHiragana', time: 120 },
    AMATERASU: { name: 'アマテラス AMATERASU', condition: 'allHiragana', time: 90 },
  },
  KATAKANA: {
    ASHIGARU: { name: '足軽 ASHIGARU', condition: 'basicKatakana', time: Infinity },
    SAMURAI: { name: '侍 SAMURAI', condition: 'basicKatakana', time: 300 },
    TAISHO: { name: '大将 TAISHŌ', condition: 'allKatakana', time: 300 },
    DAIMYO: { name: '大名 DAIMYŌ', condition: 'allKatakana', time: 180 },
    SHOGUN: { name: '将軍 SHŌGUN', condition: 'allKatakana', time: 120 },
    YAMATO_TAKERU: { name: 'ヤマトタケル YAMATO-TAKERU', condition: 'allKatakana', time: 90 },
  },
  BOTH: {
    SUSANOWO: { name: 'スサノヲ SUSANOWO', condition: 'allBoth', time: 90 }
  }
};
