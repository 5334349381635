import { Workbox } from 'workbox-window';
import React from 'react';
import ReactDOM from 'react-dom';
import Bootstrap from './assets/stylesheets/bootstrap.min.css';
import App from './components/App/App';

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    const wb = new Workbox("/sw.js");
    const updateButton = document.querySelector("#app-update");

    wb.addEventListener("waiting", event => {
      updateButton.classList.add("show");
      updateButton.addEventListener("click", () => {
        wb.addEventListener("controlling", event => {
          window.location.reload();
        });
        wb.messageSW({ type: "SKIP_WAITING" });
      });
    });

    wb.register().then(registration => {
      console.log("Service Worker registrato con successo", registration);
    }).catch(error => {
      console.error("Errore durante la registrazione del Service Worker:", error);
    });
  });
}

let appEl = document.getElementById('app');
if (!appEl) // in case of old index.html in cache
  appEl = document.querySelector('.app');

ReactDOM.render(<App />, appEl);
