import { ACHIEVEMENTS } from '../data/achievements';
import { kanaDictionary } from '../data/kanaDictionary';

export function calculateAchievements(selectedGroups, totalTime) {
  const hiraganaGroups = selectedGroups.filter(group => group.startsWith('h_'));
  const katakanaGroups = selectedGroups.filter(group => group.startsWith('k_'));

  const allHiraganaGroups = Object.keys(kanaDictionary.hiragana);
  const allKatakanaGroups = Object.keys(kanaDictionary.katakana);

  const conditions = {
    basicHiragana: hiraganaGroups.length > 0 && hiraganaGroups.length < allHiraganaGroups.length,
    allHiragana: hiraganaGroups.length === allHiraganaGroups.length,
    basicKatakana: katakanaGroups.length > 0 && katakanaGroups.length < allKatakanaGroups.length,
    allKatakana: katakanaGroups.length === allKatakanaGroups.length,
  };
  conditions.allBoth = conditions.allHiragana && conditions.allKatakana;

  const earnedAchievements = [];

  Object.entries(ACHIEVEMENTS).forEach(([type, typeAchievements]) => {
    Object.entries(typeAchievements).forEach(([key, achievement]) => {
      if (conditions[achievement.condition] && totalTime <= achievement.time) {
        earnedAchievements.push({ ...achievement, type });
      }
    });
  });

  return earnedAchievements;
}
