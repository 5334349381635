import React, { Component } from 'react';
import { kanaDictionary } from '../../data/kanaDictionary';
import ShowStage from './ShowStage';
import Question from './Question';
import Timer from '../Timer/Timer';

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showScreen: '',
      errors: [],
      konamiBuffer: '',
      debugModeEnabled: this.props.debugModeEnabled
    };
  }

  componentWillMount() {
    this.setState({showScreen: 'stage'});
  }

  componentDidMount() {
    console.log('Game componentDidMount');
    this.setState({showScreen: 'stage'});
    this.props.startStageTimer();
    
    document.addEventListener('keypress', this.handleKeyPress);
  }

  stageUp = () => {
    console.log('Game stageUp');
    this.props.endStageTimer();
    this.props.stageUp();
    this.setState({showScreen: 'stage'});
    this.props.startStageTimer();
  }

  lockStage = stage => {
    console.log('Game lockStage');
    this.setState({showScreen: 'question'});
    this.props.lockStage(stage);
  }

  showQuestion = () => {
    console.log('Game showQuestion');
    this.setState({showScreen: 'question'})
  }

  handleError = (errorKana) => {
    this.setState(prevState => ({
      errors: [...prevState.errors, errorKana]
    }));
  }

  toggleDebugMode = () => {
    this.setState(prevState => ({ debugModeEnabled: !prevState.debugModeEnabled }));
  }

  handleKeyPress = (event) => {
    const newBuffer = this.state.konamiBuffer + event.key;
    console.log('Current buffer:', newBuffer);
    this.setState({ konamiBuffer: newBuffer.slice(-10) }, () => {
      if (this.state.konamiBuffer === 'konamicode') {
        this.toggleDebugMode();
        console.log('Debug mode:', this.state.debugModeEnabled ? 'ON' : 'OFF');
        this.setState({ konamiBuffer: '' });
      }
    });
  }

  handleKeyDown = (event) => {
    if (this.props.debugModeEnabled && event.keyCode === 32) { // 32 è il codice della barra spaziatrice
      event.preventDefault();
      for (let i = 0; i < 10; i++) {
        this.handleCorrectAnswer();
      }
    }
    // ... resto del codice esistente ...
  }

  render() {
    console.log('Game render, showScreen:', this.state.showScreen);
    return (
      <div>
        {
          this.state.showScreen === 'stage' &&
            <ShowStage
              lockStage={this.lockStage}
              handleShowQuestion={this.showQuestion}
              handleEndGame={this.props.handleEndGame}
              stage={this.props.stage}
              stageTimes={this.props.stageTimes}
              totalTime={this.props.totalTime}
              debugModeEnabled={this.state.debugModeEnabled}
              toggleDebugMode={this.toggleDebugMode}
              selectedGroups={this.props.decidedGroups}
              errors={this.state.errors}
            />
        }
        {
          this.state.showScreen === 'question' &&
          <>
            <Question
  isLocked={this.props.isLocked}
  handleStageUp={this.stageUp}
  stage={this.props.stage}
  decidedGroups={this.props.decidedGroups}
  onError={this.handleError}
  debugModeEnabled={this.state.debugModeEnabled}
  toggleDebugMode={this.toggleDebugMode}
/>
            <Timer />
          </>
        }
      </div>
    );
  }
}

export default Game;
