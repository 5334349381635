import React, { Component } from 'react';
import './ShowStage.scss';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { kanaDictionary } from '../../data/kanaDictionary';
import { findRomajisAtKanaKey } from '../../data/helperFuncs';
import { calculateAchievements } from '../../utils/achievementUtils';

class ShowStage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroups: props.selectedGroups || [],
      totalTime: props.totalTime || 0,
      show: false,
      entered: false,
      errors: []
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedGroups !== prevProps.selectedGroups) {
      this.setState({ selectedGroups: this.props.selectedGroups || [] });
    }
    if (this.props.totalTime !== prevProps.totalTime) {
      this.setState({ totalTime: this.props.totalTime || 0 });
    }
  }

  componentDidMount() {
    this.setState({show: true});
    if(this.props.stage <= 4)
      this.timeoutID = setTimeout(this.removeStage, 1200); // how soon we start fading out (1500)
    window.scrollTo(0,0);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keypress', this.handleKeyPress);
  }

  removeStage = () => {
    this.setState({show: false});
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(this.props.handleShowQuestion, 1000) // how soon we go to question (1000)
  }

  showStage = () => {
    const { selectedGroups, totalTime, stageTimes, errors } = this.props;
    console.log('showStage - selectedGroups:', selectedGroups);
    console.log('showStage - totalTime:', totalTime);

    if (!selectedGroups || !Array.isArray(selectedGroups)) {
      console.error('Invalid selectedGroups:', selectedGroups);
      return <div>Error: Invalid data</div>;
    }

    if (typeof totalTime !== 'number') {
      console.error('Invalid totalTime:', totalTime);
      return <div>Error: Invalid time data</div>;
    }

    console.log("Errors passed to ShowStage:", errors);
    const achievements = calculateAchievements(selectedGroups, totalTime);

    if (this.props.stage === 5) {
      return (
        <div className="text-center show-end">
          <h1>Congratulazioni!</h1>
          <h3>Hai superato tutti i 4 livelli.</h3>
          <h4>Tempi per livello:</h4>
          {stageTimes.map((time, index) => (
            <p key={index}>Livello {index + 1}: {formatTime(time)}</p>
          ))}
          <h4>Tempo totale: {formatTime(totalTime)}</h4>
          {this.renderErrorsOrPerfect(errors)}
          {achievements.length > 0 && (
            <div className="achievements">
              <h3 className="achievement-title">
                {achievements.length === 1 ? 'Hai conquistato il titolo di:' : 'Hai conquistato i titoli di:'}
              </h3>
              <div className="achievement-container">
                {achievements.map((achievement, index) => {
                  const { description, imagePath } = this.getAchievementDescription(achievement);
                  return (
                    <div key={index} className="achievement">
                      <p className="achievement-name">{achievement.name}</p>
                      <div className="achievement-image-container">
                        <img src={imagePath} alt={achievement.name} className="achievement-image" />
                      </div>
                      <p className="achievement-description" dangerouslySetInnerHTML={{__html: description}}></p>
                      <p className="achievement-comparison" dangerouslySetInnerHTML={{__html: this.getAchievementComparison(achievement)}}></p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <p><button className="btn btn-danger keep-playing" onClick={() => this.props.lockStage(4)}>Continua a Giocare</button></p>
          <p><button className="btn btn-danger back-to-menu" onClick={this.props.handleEndGame}>Torna al Menu</button></p>
        </div>
      );
    }

    let stageDescription;     
    let stageSecondaryDescription = false;

    if (this.props.stage === 1) stageDescription = 'Scegli un kana';
    else if (this.props.stage === 2) { stageDescription = 'Scegli un kana'; stageSecondaryDescription = 'Invertito'; }
    else if (this.props.stage === 3) stageDescription = 'Scrivi la risposta';
    else if (this.props.stage === 4) { stageDescription = 'Scrivi la risposta'; stageSecondaryDescription = 'Tre alla volta'; }

    return (
      <div className="text-center show-stage">
        <h1>Livello {this.props.stage}</h1>
        <h3>{stageDescription}</h3>
        { stageSecondaryDescription ? <h4>{stageSecondaryDescription}</h4> : '' }
      </div>
    );
  }
  render() {
    const { selectedGroups } = this.state;
    if (!Array.isArray(selectedGroups)) {
      console.error('selectedGroups is not an array in render:', selectedGroups);
      return <div>Error: Invalid data</div>;
    }

    const content = this.showStage();
    const { show } = this.state;

    return (
      <CSSTransition classNames="stage" timeout={{enter: 900, exit: 900}} in={show} unmountOnExit>
        {state => content}
      </CSSTransition>
    );
  }

  getAchievementDescription(achievement) {
    const { name, type } = achievement;
    const kanaType = type === 'KATAKANA' ? 'Katakana' : 'Hiragana';
    const imagePath = `src/assets/img/${name.toLowerCase().split(' ')[0]}.jpg`;
    
    let description;
    switch (name) {
      case '足軽 ASHIGARU':
        description = `<strong>${kanaType}</strong>: Completare la challenge solo con i suoni puri (esclusi i kana alternativi)`;
        break;
      case '侍 SAMURAI':
        description = `<strong>${kanaType}</strong>: Completare la challenge con tutti i suoni (inclusi i kana alternativi)`;
        break;
      case '大将 TAISHŌ':
        description = `<strong>${kanaType}</strong>: Completare la challenge solo con i suoni puri (esclusi i kana alternativi) entro 5 minuti cronometrati`;
        break;
      case '大名 DAIMYŌ':
        description = `<strong>${kanaType}</strong>: Completare la challenge con tutti i suoni (inclusi i kana alternativi) entro 5 minuti cronometrati`;
        break;
      case '将軍 SHŌGUN':
        description = `<strong>${kanaType}</strong>: Completare la challenge con tutti i suoni (inclusi i kana alternativi) entro 3 minuti cronometrati`;
        break;
      case '巴御前 TOMOE-GOZEN':
        description = `<strong>${kanaType}</strong>: Completare la challenge con tutti i suoni (inclusi i kana alternativi) entro 2 minuti cronometrati`;
        break;
      case 'アマテラス AMATERASU':
        description = `<strong>${kanaType}</strong>: Completare la challenge con tutti i suoni (inclusi i kana alternativi) entro 1 minuto e 30 secondi`;
        break;
      case 'ヤマトタケル YAMATO-TAKERU':
        description = `<strong>${kanaType}</strong>: Completare la challenge con tutti i suoni in ${kanaType} (inclusi i kana alternativi) entro 1 minuto e 30 secondi`;
        break;
      case 'スサノヲ SUSANOWO':
        description = `<strong>Hiragana e Katakana</strong>: Completare la challenge con tutti i suoni (hiragana e katakana, inclusi i kana alternativi) entro 1 minuto e 30 secondi`;
        break;
      default:
        description = "";
    }
    
    return { description, imagePath };
  }

  getAchievementComparison(achievement) {
    const { name, type } = achievement;
    const kanaType = type === 'KATAKANA' ? 'katakana' : 'hiragana';
    
    switch (name) {
      case '足軽 ASHIGARU':
        return `Come un Ashigaru, il soldato di fanteria che formava la spina dorsale degli eserciti giapponesi, hai dimostrato una solida base nei suoni fondamentali del ${kanaType}.`;
      case '侍 SAMURAI':
        return `Hai raggiunto il livello di un Samurai, il nobile guerriero che padroneggiava non solo la spada ma anche l'arte della scrittura e della cultura, dimostrando una conoscenza completa del ${kanaType}.`;
      case '大将 TAISHŌ':
        return `La tua rapidità con i suoni base del ${kanaType} ti rende un vero Taishō, un generale che guida con saggezza e velocità, proprio come un comandante esperto nella scrittura giapponese.`;
      case '大名 DAIMYŌ':
        return `Come un potente Daimyō, un signore feudale che governava vasti territori, hai dimostrato una padronanza completa e veloce di tutti i suoni ${kanaType}, governando il tuo apprendimento con autorità.`;
      case '将軍 SHŌGUN':
        return `La tua abilità nel riconoscere rapidamente tutti i ${kanaType} ti eleva al rango di Shōgun, il comandante supremo che guidava con destrezza e strategia, proprio come domini la scrittura giapponese.`;
      case '巴御前 TOMOE-GOZEN':
        return `La tua destrezza con i ${kanaType} è leggendaria come quella di Tomoe Gozen, la famosa guerriera samurai nota per il suo coraggio e abilit in battaglia.`;
      case 'アマテラス AMATERASU':
        return `La tua maestria dei ${kanaType} brilla come Amaterasu, la dea del sole, che illumina il mondo con la sua luce divina e saggezza.`;
      case 'ヤマトタケル YAMATO-TAKERU':
        return "Come Yamato Takeru, il giovane eroe che viaggiava attraverso terre ignote e ostili, hai navigato con agilità e precisione tra i suoni del katakana, abbattendo qualsiasi barriera linguistica in un tempo record.";
      case 'スサノヲ SUSANOWO':
        return "Come Susanowo, il potente dio della tempesta, hai dimostrato una padronanza completa e rapida di entrambi i sistemi di scrittura, dominando sia l'hiragana che il katakana con la forza di una divinità.";
  
      default:
        return "";
    }
  }

  renderErrorsOrPerfect(errors) {
    console.log("Errors received:", errors);
    if (!errors || errors.length === 0) {
      return (
        <div className="perfect-message">
          <h2>Perfetto! Nessun errore!</h2>
        </div>
      );
    } else {
      return (
        <div className="errors-box">
          <h4>Errori commessi:</h4>
          <div className="error-kana-container">
            {errors.map((error, index) => (
              <span key={index} className="error-kana">
                <ruby>
                  {error}<rt>{findRomajisAtKanaKey(error, kanaDictionary)[0]}</rt>
                </ruby>
              </span>
            ))}
          </div>
        </div>
      );
    }
  }

  getRubyAnnotation(kana, romaji) {
    return (
      <ruby>
        {kana}<rt>{romaji}</rt>
      </ruby>
    );
  }

  findRomajisAtKanaKey(key, dictionary) {
    for (let kanaType in dictionary) {
      for (let group in dictionary[kanaType]) {
        if (dictionary[kanaType][group].characters[key]) {
          return dictionary[kanaType][group].characters[key];
        }
      }
    }
    return [];
  }
}

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

export default ShowStage;
